module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2FF0VXBSK5"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md",".markdown"],"remarkPlugins":[null,null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-rewrite-link-for-trailing-slash","options":{"fileIgnore":[],"rewriteToParentUrlFileIgnore":[]}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}},{"resolve":"gatsby-remark-relative-images","options":{"include":["image"]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":561,"showCaptions":["title","alt"]}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":["mdx","md","markdown",".png",".jpg",".jpeg",".bmp",".tiff",".svg",".gif"]}}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/wiki/wiki"},
    },{
      plugin: require('../node_modules/gatsby-theme-primer-wiki/gatsby-browser.js'),
      options: {"plugins":[],"nav":[{"title":"Github","url":"https://github.com/padawanR0k"}],"editUrl":"https://github.com/padawanR0k/wiki/blob/master/content/","sidebarComponents":["latest","tag"],"lastUpdatedText":"최근 수정 시각","shouldSupportLatest":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wiki By r0k","short_name":"r0k Wiki","start_url":"/","background_color":"#f7f0eb","display":"standalone","icon":"/home/runner/work/wiki/wiki/static/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"67a839d4b56c964493fdac0b9734c131"},
    }]
